* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100%;
  background-color: rgb(240, 242, 236);
}
